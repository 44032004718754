import { mapState, mapActions, mapGetters } from 'vuex';

import { wrapToLoadingFn } from '@helpers';

import { usePagination, useSearch, useDataLoading } from '@mixins/factories';

import IrOrdersSearchByTags from '../../_components/IrOrdersSearchByTags.vue';

const dataLoadingOptions = { getterName: 'getOrders' };
const paginationOptions = { itemsName: 'orders' };
const searchOptions = {
  systemQueryParams: ['pageNum', 'tags', 'negative'],
  initialSearchDataStates: [
    'customerId', 'providerId', 'dates', 'routingRule', 'lowerPrices', 'searchTerm',
  ],
  customTypes: {
    customerId: 'number',
    providerId: 'number',
    lowerPrices: 'booleanString',
    dates: 'array',
  },
};

const ordersListHeaders = Object.freeze([
  { text: '', value: 'info', width: '1%' },
  { text: 'InkRouter ID', value: 'id' },
  { text: 'Print Client ID', value: 'invoice' },
  { text: 'Print Client', value: 'printCustomerId' },
  { text: 'Order Accepted', value: 'dateCreated', width: '20%' },
]);

export default {
  name: 'Orders',

  components: {
    IrOrdersSearchByTags,
  },

  mixins: [
    useDataLoading(dataLoadingOptions),
    usePagination(paginationOptions),
    useSearch(searchOptions),
  ],

  data() {
    return {
      tags: [],

      ordersListHeaders,
    };
  },

  computed: {
    ...mapState('orders', ['orders']),

    ...mapGetters('accounts', ['clientsOptions', 'providersOptions', 'accountNameByIdFromAllAccounts']),

    searchFieldsConfig() {
      return [
        { value: 'searchTerm', label: 'ID', type: 'text' },
        {
          value: 'customerId',
          items: this.clientsOptions,
          label: 'Client',
          type: 'combobox',
        },
        {
          value: 'providerId',
          items: this.providersOptions,
          label: 'Provider',
          type: 'combobox',
        },
        {
          value: 'lowerPrices',
          items: [
            { value: true, text: 'Rule ≠ price' },
            { value: false, text: 'Rule = price' },
          ],
          label: 'Routing',
          type: 'select',
        },
        { value: 'routingRule', label: 'Routing Rule', type: 'text' },
        { value: 'dates', label: 'Date Range', type: 'daterange' },
      ];
    },
  },

  created() {
    this.getAllAccounts();
    this.getAccounts({ activeOnly: true });
  },

  methods: {
    ...mapActions('orders', ['getOrders']),

    ...mapActions('accounts', ['getAccounts', 'getAllAccounts']),

    changeAccountsState(value) {
      this.getAccounts({ activeOnly: !value });
    },

    searchByTags({ positive = [], negative = [] } = {}) {
      this.$router.push({
        query: {
          pageNum: '1',
          tags: positive.length ? positive : undefined,
          notInTags: negative.length ? negative : undefined,
        },
      }, null, this.$_fetchData);
    },

    wrapToLoadingFn,
  },
};
