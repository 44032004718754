var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-blacker-layout',{attrs:{"column":""}},[_c('ir-orders-search-by-tags',{on:{"search":_vm.searchByTags,"reset":_vm.searchByTags}}),_c('v-blacker-search',{class:{ 'search-with-tags': !_vm.searchData.tags || !_vm.searchData.tags.length },attrs:{"items":_vm.searchFieldsConfig,"hide-content-on-header-click":"","hide-content":""},on:{"reset":_vm.onResetSearch,"search":_vm.onSearch},scopedSlots:_vm._u([{key:"title-right",fn:function(){return [_c('v-blacker-layout',{attrs:{"align-center":""}},[_c('v-blacker-spacer'),_c('span',{staticClass:"vbt-margin-r-10",staticStyle:{"font-size":"17px"}},[_vm._v(" All accounts ")]),_c('v-blacker-switch',{staticClass:"custom-search-toggle",attrs:{"color":"info"},on:{"change":_vm.changeAccountsState,"click":function($event){$event.stopPropagation();}}})],1)]},proxy:true}]),model:{value:(_vm.searchData),callback:function ($$v) {_vm.searchData=$$v},expression:"searchData"}}),_c('v-blacker-content-box',{attrs:{"is-loading-process":_vm.isLoading,"loading-error":_vm.loadingError,"no-content":_vm.noContent,"go-back-btn":_vm.goBack,"title":"Information"}},[_c('v-blacker-table',{attrs:{"headers":_vm.ordersListHeaders,"items":_vm.orders},scopedSlots:_vm._u([{key:"info",fn:function(ref){
var item = ref.item;
return [(item.lowerPrices)?_c('v-blacker-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-blacker-icon',_vm._g({attrs:{"color":"warning"}},on),[_vm._v(" report_problem ")])]}}],null,true)},[_vm._v(" Lower price routing available ")]):_vm._e()]}},{key:"id",fn:function(ref){
var id = ref.item.id;
return [_c('router-link',{staticClass:"blue--text darken-1",attrs:{"to":{
            name: 'order',
            params: { id: id },
          }}},[_vm._v(" "+_vm._s(id)+" ")])]}},{key:"printCustomerId",fn:function(ref){
          var printCustomerId = ref.item.printCustomerId;
return [_vm._v(" "+_vm._s(_vm.accountNameByIdFromAllAccounts[printCustomerId])+" ")]}},{key:"dateCreated",fn:function(ref){
          var dateCreated = ref.item.dateCreated;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(dateCreated,'local', '-'))+" ")]}}])}),_c('v-blacker-pagination',{attrs:{"items-count":_vm.pagedItemsCount},model:{value:(_vm.pageNum),callback:function ($$v) {_vm.pageNum=$$v},expression:"pageNum"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }